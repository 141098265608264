html {
    scroll-behavior: smooth;
}

.font-text {
    font-family: 'DM Sans', sans-serif;
}

.font-sub {
    font-family: 'Source Code Pro', monospace;
}
